import { HttpClient } from '@angular/common/http';
import { Component, OnInit,HostListener, EventEmitter, Output, ChangeDetectorRef, AfterViewInit  } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Category } from 'src/app/_models/Category';
import { MainService } from 'src/app/_services/main.service';
import { SharedDataService } from 'src/app/_services/shared-data.service';
import { environment } from 'src/environments/environment.prod';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  readonly adsPrefixUrl:string= environment.apiUrl+"/Image/a/";
  exchangeRates$: Observable<any>;
  weatherDate$: Observable<any>;
  categories$: Observable<any[]>;
  contactInfo$: Observable<any>;

  @Output() scrollTo: EventEmitter<void> = new EventEmitter<void>();
  isFixed = false;
  currentDate:any;
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.isFixed = offset > 100; // Adjust the offset value as needed
  }
  public categoriesList: Array<Category> =new Array<Category>();
  public nextCategoriesList: Array<Category> =new Array<Category>();
  weatherData:any=[];
  categoryListSource: Array<string> = [];
  companyData:any;
  adsInfo:any;
  ads4Info:any;
  constructor(     private sharedDataService: SharedDataService, private mainService: MainService,private http: HttpClient  ) { }
  weatherDegree: number; 
  USDRates: any;
  EURRates: any;
  JODRates: any;
  EGPRates: any;
  goldRates:any;

  ngOnInit(): void {
    var arabicDate=  new Date().toLocaleDateString('ar-PS', {day: 'numeric', month: 'short',weekday: 'long',year : 'numeric'});
    this.currentDate= arabicDate;
    this.mainService.getActiveCategories().subscribe((data)=>{
      let catrgoriesList = data;
      this.categoriesList = catrgoriesList.slice(0,10);
      this.nextCategoriesList=catrgoriesList.slice(10);
     

    });
    this.fetchExchangeRates();
    this.fetchWeatherDate();
    
  
    this.sharedDataService.contactInfo$.subscribe(
      (data) => {
        // Handle the emitted data here
        let dataList = data;
        if(dataList){
          this.companyData = dataList[0];
          
        }
        
       },
      (error) => {
        // Handle any errors here
        console.error('Error fetching categories:', error);
      }
    )
       this.mainService.GetAdsByOrder("1,4").subscribe((data) => {
       let advsData= JSON.parse(JSON.stringify(data));
       
       this.adsInfo= advsData.find(a=>a.Order ===1) || null;
       this.ads4Info= advsData.find(a=>a.Order ===4) || null;
       this.sharedDataService.setads4InfoData( this.ads4Info);
       
         if(this.adsInfo != null){
          this.adsInfo.link= this.adsInfo.VideoURL;
          if (  this.adsInfo.ImagePath != null) {
                  
            this.adsInfo.ImageSrc=  environment.apiUrl + `/Image/${this.adsInfo.ImagePath}`; 
          } else {
            
            this.adsInfo.ImageSrc = "assets/img/news/Video1728-90.gif";
          }
         }
            
         });

   }

  

  scrollToSection() {
    this.scrollTo.emit();
  }



  fetchExchangeRates(): void {
    this.sharedDataService.exchangeRates$.subscribe(
      (data) => {
        // Handle the emitted data here
        let dataList = data;
        if(dataList){
           this.USDRates  = dataList.find(a=>a.base=="USD")?.rates; 
        
    
          this.EURRates  =dataList.find(a=>a.base=="EUR")?.rates;
       
          this.JODRates  =dataList.find(a=>a.base=="JOD")?.rates; 
       
          this.EGPRates  = dataList.find(a=>a.base=="EGP")?.rates;   
        }
        
       },
      (error) => {
        // Handle any errors here
        console.error('Error fetching categories:', error);
      }
    )
    
  }

  fetchWeatherDate(): void {
    this.sharedDataService.weatherDate$.subscribe(
      (data) => {
        // Handle the emitted data here
        let dataList = data;
        if(dataList){
          this.weatherData=dataList;
       
          let degree= this.weatherData.find(a=>a.CityName ==  'jerusalem')?.Temperature;
          this.weatherDegree = degree ?  degree : 0;
        }
        
       },
      (error) => {
        // Handle any errors here
        console.error('Error fetching categories:', error);
      }
    )
    
  }

 
  
  
  onCityChange(event: Event):void {
    
    const selectElement = event.target as HTMLSelectElement;
    const selectedCity = selectElement.value;
  

    let degree= this.weatherData.find(a=>a.CityName ==  selectedCity)?.Temperature;
    this.weatherDegree = degree ?  degree : 0;
    
  }

  formatRoute(text: string): string {
    return text ? text.replace(/ /g, '-') : '';
  }
}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/_services/main.service';
import { Buffer } from 'buffer';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-tobic-category',
  templateUrl: './tobic-category.component.html',
  styleUrls: ['./tobic-category.component.scss']
})
export class TobicCategoryComponent implements OnInit {
  TopicsData:any=[];
  public tobicCategoryId:string;
  public categoryName:string;
  readonly prefixUrl:string= environment.apiUrl+"/Image/view/";


  //public newsList:any=[];
  constructor(private titleService: Title,private activatedRoute: ActivatedRoute, private mainService: MainService, private sanitizer: DomSanitizer) {

   }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      params => {
        this.tobicCategoryId= params['id'];
        //this.categoryName= params['title'];
        if(  this.tobicCategoryId== '1'){
          this.categoryName="مقالات";
        }else  if(  this.tobicCategoryId== '2'){
          this.categoryName="ترجمات وردود";
        } if(  this.tobicCategoryId== '3'){
          this.categoryName="مدونات";
        }
        this.titleService.setTitle(this.categoryName +" | "+"4Dpal");
       this.selectTopic(this.tobicCategoryId);
      });
           
  }



  
  selectTopic(tabId: string) {
  
    this.mainService.getAllTobics(Number.parseInt(tabId),0).subscribe(
      data=>{
     
        this.TopicsData=  JSON.parse(JSON.stringify(data));
 
          this.TopicsData.TobicsListFirstRow=this.TopicsData.splice(0,2);
          this.TopicsData.TobicsListOtherRows=this.TopicsData;
         
      },error =>{
        console.log( "getLatestNews",error);
      }
    );
  }
  formatRoute(text: string): string {
    return text ? text.replace(/ /g, '-') : '';
  }
}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { News } from 'src/app/_models/News';
import { MainService } from 'src/app/_services/main.service';
import { Buffer } from 'buffer';
import { Category } from 'src/app/_models/Category';
import { environment } from 'src/environments/environment.prod';
import { SharedDataService } from 'src/app/_services/shared-data.service';

@Component({
  selector: 'app-tobic-details',
  templateUrl: './tobic-details.component.html',
  styleUrls: ['./tobic-details.component.scss']
})
export class TobicDetailsComponent implements OnInit {
public tobicId:string;
releatedTopicsData:any;
public tobicData:any;
//public newsReletedList:any=[];
public videoLink:string='';
ads4Data:any;
categoryName:string;
linkToCopy: string ;

public categoriesList: Array<Category> =new Array<Category>();
readonly prefixUrl:string= environment.apiUrl+"/Image/view/";
readonly adsPrefixUrl:string= environment.apiUrl+"/Image/a/";

  constructor(private sharedDataService: SharedDataService, private titleService: Title,private activatedRoute: ActivatedRoute, private mainService: MainService, private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(
      params => {
        this.tobicId= params['id'];
        this.linkToCopy=  'https://4dpal.com/tobic/'+this.tobicId ;
        this.mainService.getTobicById(this.tobicId).subscribe(
          data => {
            this.tobicData = data;
            this.titleService.setTitle(this.tobicData.SubjectTitle +" | "+"4Dpal");
            this.releatedTopicsData=data.RelatedSubjects;
           
            if(this.tobicData.NewsVideoURL != null && this.tobicData.NewsVideoURL != ''){
              let linkCode = this.tobicData.NewsVideoURL.split("=")[1];
              this.videoLink ="https://www.youtube.com/embed/"+ linkCode;
            }
            if(  this.tobicData.CategoryId== '1'){
              this.categoryName="مقالات";
            }else  if(  this.tobicData.CategoryId== '2'){
              this.categoryName="ترجمات وردود";
            } if(  this.tobicData.CategoryId== '3'){
              this.categoryName="مدونات";
            }
          },
          error => {
            // Handle errors
            console.log(  "   this.combinedData error",error);
          }  
        );

      });
   

      this.sharedDataService.sads4InfoData$.subscribe((data)=>
        {
          let adsData= data;
          if(adsData != null){
            this.ads4Data= adsData;
          }else{
            this.ads4Data=null;
          }
          
        });
  }


  copyLink() {
    navigator.clipboard.writeText(this.linkToCopy).then(() => {
    //  alert('Link copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }
  formatRoute(text: string): string {
    return text ? text.replace(/ /g, '-') : '';
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedDataService } from 'src/app/_services/shared-data.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';  
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  companyData:any;
  contactForm: FormGroup;
  siteKey: string = '6Le9DhcqAAAAAMTvbwyDvm6a3Spm525ovR9LNPfV';  // Replace with your site key
  recaptchaResponse: string;
  recaptchaError: boolean = false;

  constructor(private titleService: Title,private sharedDataService: SharedDataService,private fb: FormBuilder, private recaptchaV3Service: ReCaptchaV3Service) { }

  ngOnInit(): void {
    this.titleService.setTitle( "تواصل معنا"+" | "+"4Dpal");
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', Validators.required],
      msg_subject: ['', Validators.required],
      message: ['', Validators.required]
    });
    this.sharedDataService.contactInfo$.subscribe(
      (data) => {
        // Handle the emitted data here
        let dataList = data;
        if(dataList){
          this.companyData = dataList[0];
        }
        
       },
      (error) => {
        // Handle any errors here
        console.error('Error fetching categories:', error);
      }
    );
    this.resolveRecaptcha();
  }

  onSubmit(): void {
  //  console.log("this.recaptchaResponse",this.recaptchaResponse);
    if (this.contactForm.valid && this.recaptchaResponse) {
   //   console.log('Form Submitted:', this.contactForm.value);
      // Handle form submission here
    } else {
      if (!this.recaptchaResponse) {
        this.recaptchaError = true;
      }
      this.contactForm.markAllAsTouched(); // Show all errors
    }
  }
  resolveRecaptcha(): void {
    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      this.recaptchaResponse = token;
      this.recaptchaError = false;
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/_services/main.service';
import { Buffer } from 'buffer';
import { environment } from 'src/environments/environment';
 
@Component({
  selector: 'app-author-tobics',
  templateUrl: './author-tobics.component.html',
  styleUrls: ['./author-tobics.component.scss']
})
export class AuthorTobicComponent implements OnInit {
  TopicsData:any=[];
  public authorId:string;
  public authorName:string;
  //public newsList:any=[];
  readonly prefixUrl:string= environment.apiUrl+"/Image/view/";

  constructor(private titleService: Title,private activatedRoute: ActivatedRoute, private mainService: MainService, private sanitizer: DomSanitizer) {

   }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      params => {
        this.authorId= params['id'];
        //this.categoryName= params['title'];
       
       this.getTopics(this.authorId);
      });
           
  }



  
  getTopics(authorId: string) {
    
    this.mainService.getAllTobicsByAuthorId(authorId).subscribe(
      data=>{
     
        this.TopicsData=  JSON.parse(JSON.stringify(data));
        this.titleService.setTitle(this.TopicsData.AuthorName +" | "+"4Dpal");
          //this.firstTobicData=this.TopicsData[0];
       
      },error =>{
        console.log( "TopicsData",error);
      }
    );
  }
  formatRoute(text: string): string {
    return text ? text.replace(/ /g, '-') : '';
  }
}

<section class="practice-area pt-50 pb-70">
    <div class="container">

       
            
        <!--<div class="section-title">
            <span>HOW CAN WE HELP YOU</span>
            <h2>Our Legal Practices Areas</h2>
        </div>-->

        <div  >
            <img  class="circular-image" [src]="prefixUrl+TopicsData?.ImagePath"   alt="Case">
            
            <h2 class="section-category-title">
                المدون: {{TopicsData.AuthorName}} 
            </h2>
        </div>
        <br>
        <div class="row">
            <ng-container *ngFor="let subItem of TopicsData?.Subjects">

            <div class="col-sm-6 col-lg-4">
                 
                    <a     [routerLink]="['/tobic', formatRoute(subItem?.SubjectTitle.trim()),  subItem?.SubjectId]">

                <div class="practice-item">
                    <div  >
                        <img class="news-cover-img" [src]="prefixUrl+subItem?.ImagePath"   alt="Shape"  width="238px;">

                    </div>
                    <h5 class="news-title" style="  line-height: 32px;  ">  {{subItem.SubjectTitle}}</h5>
                    <div>  <span class="card-article-writer"> {{subItem?.AuthorName}}   </span></div>
                   
                </div>
                </a>
            </div>
            
    </ng-container>
        </div>
    </div>
</section>
 

<div class="case-details-img pt-30 pb-70">
    <div class="container">
       
        <div style="display: flex;">
            <a routerLink="/tobics/{{categoryName}}/{{tobicData?.CategoryId}}" >
                <h5 class="category-news-text">{{categoryName  }}</h5>
            </a>
         
       </div>  
        <div class="row ">
            <div class="col-lg-9 col-md-9 col-sm-12">
                <h2 style="    font-weight: bold; "> {{tobicData?.SubjectTitle}}</h2>
                <br>
                <div style="display: flex;">
                  
                  
                        <a  [routerLink]="['/authortobics', formatRoute(tobicData?.AuthorName.trim()),  tobicData?.AuthorId]">
                        <img  class="card-avatar-img" [src]="prefixUrl+tobicData?.AuthorImage"   aria-hidden="true">
                    <p   style="font-weight: bold;     display: inline;     margin-right: 7px;  "> بقلم:  {{tobicData?.AuthorName}}   </p>
                    </a>
                   
                </div>
              
                <div class="case-details-item">
                    <img [src]="prefixUrl+tobicData?.ImagePath"  style=" max-height: 35rem;" alt="Case">
                </div>
             
                <div class="news-data-border">
                    <p class="news-date-text" style="display: inline-block; margin: 0;">{{tobicData?.InsertDate | date:'yyyy-MM-dd HH:mm'}}</p>
                    <div style="display: inline-block;"   (click)="copyLink()" title="نسخ رابط مختصر">
                        <img
                            style="
                                margin-right: 20px;  
                                height: 28px;
                                cursor: pointer;
                            "
                            src="assets/img/icons/copy-hyperlink-link-icon.svg"
                            alt="Copy Link"
                        />
                    </div>
            
                    
                     
                </div>
                  <br>
                <div class="case-details-item">
                     
                        <div  [innerHTML]="tobicData?.SubjectContent"></div>
                 
                    
                </div>

                <div class="case-details-item" *ngIf="videoLink">
                    <div class="video-container">
                         <iframe *ngIf="videoLink" width="100%" height="100%" [src]="videoLink  | safe" frameborder="0" allowfullscreen></iframe>
                  </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12" style="  margin-top: 9rem; ">
                <div class="block-advertisement" *ngIf="ads4Data ==null">  
                    <b> منطقة اعلان 208*306</b>
                </div>
                <div class="block-advertisement" *ngIf="ads4Data !=null"> 

                    <a href="{{ads4Data?.Link}}" target="_blank"
                        ><img
                            style="width: 100%"
                            class="td-retina img-responsive"
                            [src]="adsPrefixUrl+ads4Data?.ImagePath"
                            alt=""
                            loading="lazy"
                    /></a>
                </div>
               <div class="block-news" *ngIf="releatedTopicsData != null && releatedTopicsData.length>0">
                <div class=" pa6">
                    <a routerLink="/" class="bb1">
                        <h2 class="tobic-header">اقرأ ايضا
                            <i class="fa fa-sort-desc" aria-hidden="true"></i>
    
                        </h2>
                        
                    </a>
                </div>
                <div >
                    <ng-container *ngFor="let item of  releatedTopicsData; let i = index;">
                    <div class="card card-article-link" style="    border: none !important;">
                   
                            <a   class=" bb1gray " [routerLink]="['/tobic', formatRoute(item?.SubjectTitle.trim()),  item?.SubjectId]">
                            <div class="card-body" style="background: none">
                                 
                                <div class="row">
                                    <div
                                        class="col-lg-5 col-md-5 col-xs-12 card-img-new"
                                    >
                                        <img
                                            style="  max-height: 7rem;"
                                            class="img-fluid responsive-image"
                                            [src]="prefixUrl+item?.ImagePath" 
                                            alt="Blog"
                                        />
                                    </div>
                                    <div
                                        class="col-lg-7 col-md-7 col-xs-12 card-text"
                                    >
                                        <h6
                                            class="  news-content"
                                            style="font-weight: bold"
                                        >
                                        {{item?.SubjectTitle}}   
                                        </h6>

                                       
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </ng-container>
               
                   
                </div>
               </div>
            </div>
        </div>
       
    </div>
</div>
 >


<div class="page-title-area page-title-area-two title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2> تواصل معنا</h2>

                <ul>
                    <li><a routerLink="/">الرئيسية</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>  تواصل معنا</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="contact-form contact-form-four pb-100">
    <div class="loaction-area">
        <div class="container">
            <div class="row location-bg">
                <div class="col-sm-6 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-pin"></i>
                        </div>
                        <h3>المواقع</h3>
                        <ul>
                            <li> {{companyData?.Street}}/ {{companyData?.Neighborhood}}/ {{companyData?.City}}/ {{companyData?.Countery}}</li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-call"></i>
                        </div>

                        <h3>هاتف</h3>

                        <ul>
                            <li><a href="tel:{{companyData?.PhoneNumber}}"> {{companyData?.PhoneNumber}}</a></li>
                          
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-email"></i>
                        </div>
                        
                        <h3>البريد الالكتروني</h3>

                        <ul>
                            <li>   <a href="mailto:{{companyData?.Email}}">
                                {{companyData?.Email}}</a></li>
                        
                        </ul>
                    </div>
                </div>
                
            </div>
            <br>
            <div class="row">
              <div class="col-12 soical-contact-row">
                <div class="soical-contact ">
                  <ul style="margin: 0; padding: 0;">
                      <li>
                          <a
                          href="{{companyData?.YoutubeURL}}" 
                              target="_blank">
                           
                              <img src="assets/img/social-icons/icons8-youtube.svg" />
                          </a>
                      </li>
                      <li>
                          <a
                          href="{{companyData?.FacebookURl}}" 
                              target="_blank">
                              <img src="assets/img/social-icons/icons8-facebook.svg"  />
                          </a>
                      </li>
                      <li>
                          <a
                          href="{{companyData?.InstagramURL}}" 
                              target="_blank"
                              > 
                              <img src="assets/img/social-icons/icons8-instagram.svg"  />
                          </a>
                      </li>
                      <li>
                          <a
                          href="{{companyData?.TwitterURL}}" 
                              target="_blank"
                              >   
                              <img src="assets/img/social-icons/icons8-x.svg" />
                          </a>
                      </li>  
                      <li>
                          <a
                          href="{{companyData?.TikTokURL}}" 
                              target="_blank"
                              > 
                              <img src="assets/img/social-icons/icons8-tiktok.svg"  />
                          </a>
                      </li>
                        <li>
                          <a
                          href="{{companyData?.TelegramURL}}" 
                              target="_blank"
                              > 
                              <img src="assets/img/social-icons/icons8-telegram.svg" />
                          </a>
                      </li> 
                      <li> 
                          <a
                          href="https://www.whatsapp.com/channel/{{companyData?.WhatsAppURL}}" 
                              target="_blank"
                              > 
                              <img src="assets/img/social-icons/icons8-whatsapp.svg"  />
                          </a>
                      </li>
                  </ul>
              </div>
            </div>
            </div>
        </div>
    </div>

    <!--<div class="container-fluid">
      <h2 style="text-align: center;">راسلنا</h2> 
      <br>
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
            <div class="row contact-wrap">
              <div class="col-sm-6 col-lg-6">
                <div class="form-group">
                  <input type="text" formControlName="name" class="form-control" placeholder="الاسم الكامل">
                  <div *ngIf="contactForm.get('name').invalid && contactForm.get('name').touched" class="text-danger">
                    <div *ngIf="contactForm.get('name').errors.required">يرجى كتابة الاسم</div>
                  </div>
                </div>
              </div>
          
              <div class="col-sm-6 col-lg-6">
                <div class="form-group">
                  <input type="email" formControlName="email" class="form-control" placeholder="البريد الالكتروني">
                  <div *ngIf="contactForm.get('email').invalid && contactForm.get('email').touched" class="text-danger">
                    <div *ngIf="contactForm.get('email').errors.required">يرجى كتابة البريد الالكتروني</div>
                    <div *ngIf="contactForm.get('email').errors.email">يرجى إدخال بريد إلكتروني صالح</div>
                  </div>
                </div>
              </div>
          
              <div class="col-sm-6 col-lg-6">
                <div class="form-group">
                  <input type="text" formControlName="phone_number" class="form-control" placeholder="رقم الجوال">
                  <div *ngIf="contactForm.get('phone_number').invalid && contactForm.get('phone_number').touched" class="text-danger">
                    <div *ngIf="contactForm.get('phone_number').errors.required">برجى كتابة رقم الجوال</div>
                  </div>
                </div>
              </div>
          
              <div class="col-sm-6 col-lg-6">
                <div class="form-group">
                  <input type="text" formControlName="msg_subject" class="form-control" placeholder="الموضوع">
                  <div *ngIf="contactForm.get('msg_subject').invalid && contactForm.get('msg_subject').touched" class="text-danger">
                    <div *ngIf="contactForm.get('msg_subject').errors.required">يرجى كتابة الموضوع</div>
                  </div>
                </div>
              </div>
          
              <div class="col-md-12 col-lg-12">
                <div class="form-group">
                  <textarea formControlName="message" class="form-control" cols="30" rows="8" placeholder=" الرسالة"></textarea>
                  <div *ngIf="contactForm.get('message').invalid && contactForm.get('message').touched" class="text-danger">
                    <div *ngIf="contactForm.get('message').errors.required">يرجى كتابة الرسالة</div>
                  </div>
                </div>
              </div>
            
                <div class="col-md-12 col-lg-12">
                    <div class="g-recaptcha" [attr.data-sitekey]="siteKey"></div>
                    <div *ngIf="recaptchaError" class="text-danger">يرجى التحقق بواسطة reCAPTCHA</div>
                </div>
              <div class="col-md-12 col-lg-12">
                <div class="text-center">
                  <button type="submit" class="contact-btn" [disabled]="contactForm.invalid">ارسل</button>
                </div>
              </div>
            </div>
          </form>
    </div>-->
</div>

<!-- <div class="map-area">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d158857.72810813924!2d-0.24168018863114898!3d51.528771840455114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2sbd!4v1591087986221!5m2!1sen!2sbd"></iframe>
</div> -->